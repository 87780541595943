

.form-control {
    display: block;
    width: 100%;
    height: auto;
    padding: 1em;
    font-size: 14px;
    font-weight: 400;
    line-height: 1;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: solid 1px var(--teo_GrayFormBorder);
    border-radius: .25rem;
    transition: border-color 0.15s ease-in-out,box-shadow 0.15s ease-in-out;

    &::placeholder {
        font-size:14px;
        color: var(--teo_LightGray);
    }

    
}

.form-control:focus {
    color: #495057;
    background-color: #fff;
    border-color: transparent;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(44, 62, 80, 0.2);
}