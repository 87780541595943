.teo_btn {
    // font-family: "euclid_circular_abold",sans-serif;
    font-family: $font-family-semibold;
    font-weight: 600;
    line-height: 1;
    border-radius: 4px;
    border: solid 2px transparent;
}

//sizes

.teo_btn_sml {
    padding: 10px 20px;
    font-size: 16px;
}

.teo_btn_mdm {
    padding: 12px 24px;
    font-size: 18px;
}

.teo_btn_lrg {
    padding: 14px 38px;
    font-size: 22px;
}

//colors & styles

.teo_btn_bg_red {
    background: var(--teo_MasterRed);
    color: var(--blanco);

    &:hover {
        background: var(--teo_MasterGray);
        color:var(--blanco);
        text-decoration: none;
    }
}

.teo_btn_bg_gray {
    background: var(--teo_MasterGray);
    color: var(--blanco);

    &:hover {
        background: var(--teo_LightGray);
        color:var(--blanco);
        text-decoration: none;
    }
}

.teo_btn_bg_darkblue {
    background: var(--teo_DarkBlue);
    color: var(--blanco);

    &:hover {
        background: var(--blanco);
        color:var(--teo_DarkBlue);
        text-decoration: none;
    }
}

.teo_btn_bg_pagos {
    background: var(--teo_Pagos);
    color: var(--teo_DarkBlue);

    &:hover {
        background: var(--blanco);
        color:var(--teo_DarkBlue);
        text-decoration: none;
    }
}

.teo_btn_border_white {
    background: transparent;
    color: var(--blanco);
    border:solid 2px white;

    &:hover {
        background: var(--blanco);
        color:var(--teo_MasterGray);
        text-decoration: none;
    }
}

@media(max-width:992px) {
    .teo_btn_bg_red {
        background: var(--teo_MasterRed);
        color: var(--blanco);
    
        &:hover {
            background: var(--teo_MasterGray);
            color:var(--blanco);
            text-decoration: none;
            border-color:var(--blanco);
        }
    }
}

@media(max-width:576px) {
    .btn-fw-mb {
        width: 100%;
        display: block;
        text-align: center;
    }
}