//Main colors
:root {
    --teo_MasterRed:          #ff3127;
    --teo_MasterGray:         #2c3e50;
    --teo_LightGray:          #808b96;
    --teo_LightGraySuave:     #eceeef;
    --teo_LightGraySuavecito: #f5f5f5;
    --teo_LightGrayGrowth:    #e5e6e6;
    --teo_Pagos:              #37ed9a;
    --teo_Tracks:             #fcba00;
    --teo_TracksBlack:        #303133;
    --teo_Cargo:              #59dfff;
    --teo_DarkBlue:           #02224b;
    --teo_Software:           #4b53fd;
    --teo_Growth:             #2d0d90;
    --teo_Shoppi:             #A033FF;
    --teo_Celestito:          #ecf1f8;
    --teo_GrayFormBorder:     #EAEAEA;
    --blanco:                 #ffffff;
  }

$body-color: var(--teo_MasterGray);
$body-bg: var(--blanco);

//Main typography

$font-family-base:          'Euclid Circular B', 'euclid_circular_aregular', sans-serif;
$font-family-base-italic:   'Euclid Circular B', 'euclid_circular_aitalic', sans-serif;

$font-family-light:         'Euclid Circular B', 'euclid_circular_alight', sans-serif;
$font-family-light-italic:  'Euclid Circular B', 'euclid_circular_alight_italic', sans-serif;

$font-family-medium:        'Euclid Circular B', 'euclid_circular_amedium', sans-serif;
$font-family-medium-italic: 'Euclid Circular B', 'euclid_circular_aMdIt', sans-serif;

$font-family-bold:          'Euclid Circular B', 'euclid_circular_abold', sans-serif;
$font-family-bold-italic:   'Euclid Circular B', 'euclid_circular_abold_italic', sans-serif;

$font-family-semibold:      'Euclid Circular B', 'euclid_circular_asemibold', sans-serif;
