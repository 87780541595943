section.teo-tabs-section {
    margin-top: 40px;
}

.teo-pill-tabs {
    width: fit-content;
    background: var(--blanco);
    margin: 0 auto;
    border-radius: 2em;
    box-shadow: 1px 3px 12px rgba(0,0,0,0.1);
    border-bottom: 0;

    li {
        a.nav-link {
            padding: 0.98em 3.7em;
            font-size: 14px;
            font-weight: normal;
            background: transparent;
            border: none;
            border-radius: 2em;
            color:var(--teo_LightGray);

            &:hover {
                background: rgb(236,238,239);
            }
        }

        a.active {
            font-weight: bold;
        }

        &:first-child {
            a.nav-link {
                &:hover {
                    background: rgb(236,238,239);
                    background: linear-gradient(90deg, rgba(236,238,239,1) 70%, rgba(255,255,255,1) 100%);
                }
            }
        }

        &:last-child {
            a.nav-link {
                &:hover {
                    background: rgb(236,238,239);
                    background: linear-gradient(270deg, rgba(236,238,239,1) 70%, rgba(255,255,255,1) 100%);
                }
            }
        }
    }
}

@media (max-width:420px) {
    .teo-pill-tabs { 
        li {
            a.nav-link {
                padding: 0.98em 2em;
            }
        }
    }
}


//Tabs content

.teo-tabs-content {
    margin-top: 40px;
    
    img {
        border-radius: 1em;
        width:540px;
        max-width:100%;
        margin-bottom: 1em;
    }
}

@media(max-width:992px) {
    .teo-tabs-content {
        .container {
            padding: 0 0;
        }
    }
}

//Diferentes colores en los tabs

.teo-id-tabs {
    li {
        a.nav-link.active {
            background: var(--teo_MasterGray);
        }
        &:first-child {  
            a.nav-link.active {
                &:hover {
                    background: var(--teo_MasterGray);
                }
               
            }
        }

        &:last-child {
            a.nav-link.active {
                &:hover {
                    background: var(--teo_MasterGray);
                } 
            }
        }
    }
}

.teo-shoppi-tabs {
    li {
        a.nav-link.active {
            background: var(--teo_Shoppi);
        }
        &:first-child {  
            a.nav-link.active {
                &:hover {
                    background: var(--teo_Shoppi);
                }
               
            }
        }

        &:last-child {
            a.nav-link.active {
                &:hover {
                    background: var(--teo_Shoppi);
                } 
            }
        }
    }
}

