@font-face {
    font-family: 'euclid_circular_abold';
    src: url('../fonts/euclidcirculara-bold-webfont.woff2') format('woff2'),
         url('../fonts/euclidcirculara-bold-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}

@font-face {
    font-family: 'euclid_circular_abold_italic';
    src: url('../fonts/euclidcirculara-bolditalic-webfont.woff2') format('woff2'),
         url('../fonts/euclidcirculara-bolditalic-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}

@font-face {
    font-family: 'euclid_circular_alight';
    src: url('../fonts/euclidcirculara-light-webfont.woff2') format('woff2'),
         url('../fonts/euclidcirculara-light-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}

@font-face {
    font-family: 'euclid_circular_alight_italic';
    src: url('../fonts/euclidcirculara-lightitalic-webfont.woff2') format('woff2'),
         url('../fonts/euclidcirculara-lightitalic-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}

@font-face {
    font-family: 'euclid_circular_amedium';
    src: url('../fonts/euclidcirculara-medium-webfont.woff2') format('woff2'),
         url('../fonts/euclidcirculara-medium-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}

@font-face {
    font-family: 'euclid_circular_aMdIt';
    src: url('../fonts/euclidcirculara-mediumitalic-webfont.woff2') format('woff2'),
         url('../fonts/euclidcirculara-mediumitalic-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}

@font-face {
    font-family: 'euclid_circular_aregular';
    src: url('../fonts/euclidcirculara-regular-webfont.woff2') format('woff2'),
         url('../fonts/euclidcirculara-regular-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}

@font-face {
    font-family: 'euclid_circular_aitalic';
    src: url('../fonts/euclidcirculara-regularitalic-webfont.woff2') format('woff2'),
         url('../fonts/euclidcirculara-regularitalic-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}

@font-face {
    font-family: 'euclid_circular_asemibold';
    src: url('../fonts/euclidcirculara-semibold-webfont.woff2') format('woff2'),
         url('../fonts/euclidcirculara-semibold-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}

@font-face {
    font-family: 'euclid_circular_aSBdIt';
    src: url('../fonts/euclidcirculara-semibolditalic-webfont.woff2') format('woff2'),
         url('../fonts/euclidcirculara-semibolditalic-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}


/* --------------- NEW FONTS------------------------------------------------- */

@font-face {
    font-family: 'Euclid Circular B';
    src: url('../fonts/euclid/EuclidCircularB-Bold.eot');
    src: local('Euclid Circular B Bold'), local('EuclidCircularB-Bold'),
        url('../fonts/euclid/EuclidCircularB-Bold.eot?#iefix') format('embedded-opentype'),
        url('../fonts/euclid/EuclidCircularB-Bold.woff2') format('woff2'),
        url('../fonts/euclid/EuclidCircularB-Bold.woff') format('woff'),
        url('../fonts/euclid/EuclidCircularB-Bold.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'Euclid Circular B';
    src: url('../fonts/euclid/EuclidCircularB-Italic.eot');
    src: local('Euclid Circular B Italic'), local('EuclidCircularB-Italic'),
        url('../fonts/euclid/EuclidCircularB-Italic.eot?#iefix') format('embedded-opentype'),
        url('../fonts/euclid/EuclidCircularB-Italic.woff2') format('woff2'),
        url('../fonts/euclid/EuclidCircularB-Italic.woff') format('woff'),
        url('../fonts/euclid/EuclidCircularB-Italic.ttf') format('truetype');
    font-weight: normal;
    font-style: italic;
}

@font-face {
    font-family: 'Euclid Circular B';
    src: url('../fonts/euclid/EuclidCircularB-Medium.eot');
    src: local('Euclid Circular B Medium'), local('EuclidCircularB-Medium'),
        url('../fonts/euclid/EuclidCircularB-Medium.eot?#iefix') format('embedded-opentype'),
        url('../fonts/euclid/EuclidCircularB-Medium.woff2') format('woff2'),
        url('../fonts/euclid/EuclidCircularB-Medium.woff') format('woff'),
        url('../fonts/euclid/EuclidCircularB-Medium.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'Euclid Circular B';
    src: url('../fonts/euclid/EuclidCircularB-SemiBoldItalic.eot');
    src: local('Euclid Circular B SemiBold Italic'), local('EuclidCircularB-SemiBoldItalic'),
        url('../fonts/euclid/EuclidCircularB-SemiBoldItalic.eot?#iefix') format('embedded-opentype'),
        url('../fonts/euclid/EuclidCircularB-SemiBoldItalic.woff2') format('woff2'),
        url('../fonts/euclid/EuclidCircularB-SemiBoldItalic.woff') format('woff'),
        url('../fonts/euclid/EuclidCircularB-SemiBoldItalic.ttf') format('truetype');
    font-weight: 600;
    font-style: italic;
}

@font-face {
    font-family: 'Euclid Circular B';
    src: url('../fonts/euclid/EuclidCircularB-BoldItalic.eot');
    src: local('Euclid Circular B Bold Italic'), local('EuclidCircularB-BoldItalic'),
        url('../fonts/euclid/EuclidCircularB-BoldItalic.eot?#iefix') format('embedded-opentype'),
        url('../fonts/euclid/EuclidCircularB-BoldItalic.woff2') format('woff2'),
        url('../fonts/euclid/EuclidCircularB-BoldItalic.woff') format('woff'),
        url('../fonts/euclid/EuclidCircularB-BoldItalic.ttf') format('truetype');
    font-weight: bold;
    font-style: italic;
}

@font-face {
    font-family: 'Euclid Circular B';
    src: url('../fonts/euclid/EuclidCircularB-MediumItalic.eot');
    src: local('Euclid Circular B Medium Italic'), local('EuclidCircularB-MediumItalic'),
        url('../fonts/euclid/EuclidCircularB-MediumItalic.eot?#iefix') format('embedded-opentype'),
        url('../fonts/euclid/EuclidCircularB-MediumItalic.woff2') format('woff2'),
        url('../fonts/euclid/EuclidCircularB-MediumItalic.woff') format('woff'),
        url('../fonts/euclid/EuclidCircularB-MediumItalic.ttf') format('truetype');
    font-weight: 500;
    font-style: italic;
}

@font-face {
    font-family: 'Euclid Circular B';
    src: url('../fonts/euclid/EuclidCircularB-SemiBold.eot');
    src: local('Euclid Circular B SemiBold'), local('EuclidCircularB-SemiBold'),
        url('../fonts/euclid/EuclidCircularB-SemiBold.eot?#iefix') format('embedded-opentype'),
        url('../fonts/euclid/EuclidCircularB-SemiBold.woff2') format('woff2'),
        url('../fonts/euclid/EuclidCircularB-SemiBold.woff') format('woff'),
        url('../fonts/euclid/EuclidCircularB-SemiBold.ttf') format('truetype');
    font-weight: 600;
    font-style: normal;
}

@font-face {
    font-family: 'Euclid Circular B';
    src: url('../fonts/euclid/EuclidCircularB-Light.eot');
    src: local('Euclid Circular B Light'), local('EuclidCircularB-Light'),
        url('../fonts/euclid/EuclidCircularB-Light.eot?#iefix') format('embedded-opentype'),
        url('../fonts/euclid/EuclidCircularB-Light.woff2') format('woff2'),
        url('../fonts/euclid/EuclidCircularB-Light.woff') format('woff'),
        url('../fonts/euclid/EuclidCircularB-Light.ttf') format('truetype');
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: 'Euclid Circular B';
    src: url('../fonts/euclid/EuclidCircularB-Regular.eot');
    src: local('Euclid Circular B Regular'), local('EuclidCircularB-Regular'),
        url('../fonts/euclid/EuclidCircularB-Regular.eot?#iefix') format('embedded-opentype'),
        url('../fonts/euclid/EuclidCircularB-Regular.woff2') format('woff2'),
        url('../fonts/euclid/EuclidCircularB-Regular.woff') format('woff'),
        url('../fonts/euclid/EuclidCircularB-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Euclid Circular B';
    src: url('../fonts/euclid/EuclidCircularB-LightItalic.eot');
    src: local('Euclid Circular B Light Italic'), local('EuclidCircularB-LightItalic'),
        url('../fonts/euclid/EuclidCircularB-LightItalic.eot?#iefix') format('embedded-opentype'),
        url('../fonts/euclid/EuclidCircularB-LightItalic.woff2') format('woff2'),
        url('../fonts/euclid/EuclidCircularB-LightItalic.woff') format('woff'),
        url('../fonts/euclid/EuclidCircularB-LightItalic.ttf') format('truetype');
    font-weight: 300;
    font-style: italic;
}

/*----------------END NEW FONTS----------------------------------------------*/

//text-colors
.teo_txt-red {
    color:var(--teo_MasterRed);
}

.teo_txt-shoppi {
    color:var(--teo_Shoppi);
}

.teo_txt-software {
    color:var(--teo_Software);
}

.teo_txt-darkblue {
    color:var(--teo_DarkBlue);
}

.teo_txt-cargo {
    color:var(--teo_Cargo);
}

.teo_txt-tracks {
    color: var(--teo_Tracks);
}

.teo_txt-pagos {
    color: var(--teo_Pagos);
}

.teo_txt-growth {
    color: var(--teo_Growth);
}

.teo_txt-tracks-black {
    color: var(--teo_TracksBlack);
}

.txt-white {
    color: var(--blanco);
}



//titulares

h1,h2,h3,h4,h5,h6 {
    font-weight: 700;
}

h1 {
    font-weight:bold;
    font-family: $font-family-bold;
    font-size:80px;
    line-height: 1em;
    margin-bottom: .5em;
}

h2 {
    font-weight:bold;
    font-family: $font-family-bold;
    font-size:60px;
    line-height: 1em;
    margin-bottom: .5em;
}

h3 {
    font-weight:bold;
    font-family: $font-family-bold;
    font-size:40px !important;
    line-height: 1em;
    margin-bottom: .5em;
}

h4 {
    font-weight:bold;
    font-family: $font-family-bold;
    font-size:30px;
    line-height: 1em;
    margin-bottom: .5em;
}

h5 {
    font-weight:bold;
    font-family: $font-family-bold;
    font-size:25px;
    line-height: 1em;
    margin-bottom: .5em;
}

h6 {
    font-weight:bold;
    font-family: $font-family-bold;
    font-size:20px;
    line-height: 1em;
    margin-bottom: .5em;
}

//font weight

.txt-regular {
    font-weight: normal;
}

.txt-medium {
    font-family: $font-family-medium;
    font-weight: 500;
}

.txt-bold {
    font-family: $font-family-bold;
    font-weight: 700;
}

.txt-medium-italic {
    font-family: $font-family-medium-italic;
    font-weight: 500;
}

.txt-bold-italic {
    font-family: $font-family-bold-italic;
    font-weight: 700;
}

//texto corrido

p {
    font-size:16px;
    padding-bottom: 2em;
}

.txt-xsmll {
    font-size:12px;
}

.txt-smll {
    font-size:14px;
}

.txt-mdm {
    font-size:21px;
}


@media(max-width:992px) {
    h1 {
        font-size:60px;
    }
    h2 {
        font-size:40px;
    }
    h3 {
        font-size:30px;
    }
    h4 {
        font-size:24px;
    }
    h5 {
        font-size: 20px;
    }
    h6 {
        font-size: 18px;
    }

    .txt-mdm {
        font-size: 16px;
    }

}