section {
    padding-top:80px;
    padding-bottom: 80px;
}

section.section-mdm {
    padding-top: 40px;
    padding-bottom: 40px;
}

section.section-sml {
    padding-top: 20px;
    padding-bottom: 20px;
}

//Primer section despúes del header va un padding para no meterse debajo del header fixed
body section:nth-child(2) {
    margin-top: 88px;
}

.bg-teo-id {
    background-color: var(--teo_MasterGray);
}

.bg-teo-pagos {
    background-color: var(--teo_Pagos);
}

.bg-teo-cargo {
    background-color: var(--teo_Cargo);
}

.bg-teo-tiendas {
    background-color: var(--teo_MasterRed);
}

.bg-teo-tracks {
    background-color: var(--teo_Tracks);
}

.bg-teo-growth {
    background-color: var(--teo_Growth);
}

.bg-teo-software {
    background-color: var(--teo_Software);
}

.bg-teo-shoppi {
    background-color: var(--teo_Shoppi);
}

.bg-gray-suave {
    background-color: var(--teo_LightGraySuave);
}

.bg-gray-suavecito {
    background-color: var(--teo_LightGraySuavecito);
}

.bg-teo-gray-growth {
    background-color: var(--teo_LightGrayGrowth);
}

a.icon-hover-toggler {
    display: block;
    width: 80px;
    height: 80px;
    margin: 0 auto;
    
    img.icon-off {
        // display:inherit;
        opacity:1;
        position: absolute;
        transition: opacity 0.3s;
        -webkit-transition: opacity 0.3s;
        left: calc(50% - 32px);
    }
    img.icon-on {
        // display:none;
        opacity:0;
        position: absolute;
        transition: opacity 0.3s;
        -webkit-transition: opacity 0.3s;
        left: calc(50% - 32px);
    }

    &:hover {
        img.icon-off {
            // display:none;
            opacity:0;

        }
        img.icon-on {
            // display:inherit;
            opacity:1;

        }
    }
}


@media(max-width:992px) {
    
    
    section {
        padding-top:40px;
        padding-bottom: 40px;
    }
    
    .container {
        width: 100%;
        padding: 0 9%;
        max-width: unset;
    }
}