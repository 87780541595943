header {
    position: fixed;
    width: 100%;
    background: white;
    z-index: 999;
    top:0;

    nav.navbar {
        padding:24px 0;
        a.navbar-brand {
            padding-top: 0;
            padding-bottom: 0;
        }

        .navbar-toggler {        
            padding: 12px 13px;
            border:0;
            .navbar-toggler-icon {
                background-image:url("../images/close.svg");
                display: inline-block;
                width: 18px;
                height: 12px;
                vertical-align: middle;
                content: "";
            }
        }

        .navbar-toggler.collapsed {       
            padding: 12px 13px;
            border:0;

            .navbar-toggler-icon {
                background-image:url("../images/hambur.svg");
                display: inline-block;
                width: 18px;
                height: 12px;
                vertical-align: middle;
                content: "";
            }
        }
    }

    ul.navbar-nav {
        li.nav-item {
            a.nav-link {
                font-size: 17px;
                color: var(--teo_MasterGray);
                text-decoration: none;
                font-family: $font-family-medium;
                padding: 0px 2rem;

                &:hover {
                    color: var(--teo_MasterRed);
                }
            }
            .dropdown-toggle::after {
                content:none;
            }
            //submenu
            .dropdown-menu {
                font-size:14px;
                border: 1px solid #eceeef;
                border-radius: 0;
                padding:0;

                .dropdown-item {
                    padding: 12px 16px;

                    &:hover {
                        background:var(--teo_MasterRed);
                        color: var(--blanco);
                    }
                }
            }
        }

        li.contactanos_cta {
            a {
                margin-left: 40px;
            }
        }
    }
}

@media (max-width:992px) {
    //Hamburguesa activada
    header {
        position: fixed;
        width: 100%;
        z-index: 999;
        background:white;
        top:0;

        nav.navbar {
            padding:22px 0;
        }
        

        .navbar-collapse, .navbar-collapse.collapsing, .navbar-collapse.show, .navbar-collapse.collapsed {
            position: absolute;
            top: 0px;
            width: 121.80%;
            left: -10.9%;
            background: var(--teo_MasterGray);
            z-index: -1;
            padding-left: 10%;
            padding-right: 10%;

            ul.navbar-nav {
                height: 100vh;
                padding-top: 80px;
                li.nav-item {
                    a.nav-link {
                        font-size: 34px;
                        color: var(--blanco);
                        text-decoration: underline;
                        font-family: $font-family-medium;
                        padding-top: 20px;
                        padding-left: 0;
        
                        &:hover {
                            color: var(--teo_MasterRed);
                        }
                    }

                    .dropdown-menu {
                        font-size:14px;
                        border: 0;
                        border-radius: 0;
                        padding:0;
                        background:transparent;
        
                        .dropdown-item {
                            padding: 12px 16px;
                            color:var(--blanco);
        
                            &:hover {
                                background:var(--teo_MasterRed);
                                color: var(--blanco);
                            }
                        }
                    }
                }

                li.contactanos_cta {
                    a {
                        margin-left: 0px; 
                        margin-top: 40px;
                        float: left;
                    }
                }
            }
        }
    }
}
