.hero-img {
    padding-bottom: 40px;
}

//soluciones

.row-soluciones {
    .col-sm-12 {
        margin-bottom: 1.5em;
    }
    padding-top: 40px;
}

a.teo-soluciones-item {
    display: flex;
    padding: 1.5em;
    border-radius: .5em;
    flex-direction: column;
    align-items: center;
    transition: 0.3s;
    height: 100%;
    justify-content: space-between;
    

    h6 {
        color:var(--blanco);
        text-decoration: none;
        align-self: start;
    }

    img {
        margin: 19.53% 0px;
        transition: 0.3s;
       
    }

    &:hover {
        text-decoration: none;
        filter: brightness(110%); 

        img {
            transform: translateY(-10%);
        }
    }
}


//casos

.teo-casos-container {
    margin-top: 20px;
}

.caso-item {
    border-radius: 1em;
    min-height: 380px;
    background-size: cover;
    background-position: center center;
    margin: 0 15px;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;

    .cliente-desc {
        z-index: 1;
        position: relative;
        padding: 1em 2em;
        display: flex;
        align-items: c;
        justify-content: left;
        align-items: center;

        img {
            width: 24px;
            height: 24px;
            border-radius: 50%;
        }

        p {
            margin: 0;
            padding: 0;
            margin-left: .5em;
        }
    }

    a {
        z-index: 2;
        position: relative;
        display: block;
        padding: 0em 2em 2em 2em;
    }
 
}


.blacklayer {
    min-height: 380px;
    background: rgba(0,0,0,0.3);
    position: relative;
    top: 0;
    border-radius: 1em; 
    z-index: 0;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
}

section.teo_clientes {
    img {
        height: 100px;
        object-fit: cover;
        width: 380px;
    }
}

@media (max-width:992px) {

    .caso-item {
        width:100%;
        margin: 0 40px;
        min-width: unset;
    }
    .blacklayer {
        width:100%;
        min-width: unset;
    }
}

@media (max-width:576px) {
    .caso-item {
        margin: 0 24px;
    }
}