.casos-hero {
    background-size: cover;
    background-position: left center;

    .container {
        padding-top: 7em;
        padding-bottom: 7em;
    }
}

section.teo_hero.casos-hero.casos-sintexto {
    padding-top: 166px;
    padding-bottom: 166px;
    background-position: center top;

    h2 {
        color:transparent !important;
    }
}

@media(max-width:1200px) {
    section.teo_hero.casos-hero.casos-sintexto {
        padding-top: 30px;
        padding-bottom: 30px;
        background-size: cover;
        background-position: center top;
        background-repeat: no-repeat;
    }
}

@media(max-width:650px) {
    section.teo_hero.casos-hero.casos-sintexto {
        padding-top: 0px;
        padding-bottom: 0px;
        background-size: contain;
        background-position: center top;
        background-repeat: no-repeat;
    }

    .casos-hero .container {
        padding-top: 7em;
        padding-bottom: 3em;
    }
}

@media (max-width: 400px) {
    .casos-hero .container {
        padding-top: 7em;
        padding-bottom: 1em;
    }
}

.productos-iconos {
    img {
        border-radius: 0;
    }
    .col-6 {
        padding: .5em 1em;
    }
}

.col-img-casos {
    position: relative;

    img.logo-caso {
        position: absolute;
        right: 0;
        top: -10px;
        width:56px;
    }
}

.testimonioinfo {
    img {
        width: 80px;
        height: 80px;
        object-fit: cover;
        border-radius: 50%;
        border: solid 1px var(--teo_LightGray);
    }
}

section.cargar-mas {
    a {
        color:var(--teo_LightGray);
        text-decoration: none;

        &:hover {
            color:var(--teo_MasterGray);
        }
    }
}