footer {
    border-top:solid 1px #EAEAEA;
    width:100%;
    padding-top: 60px;

    .footer-top {
        padding-bottom: 40px;
    }

    .teo-about-footer {
        padding-right: 5%;
        img.logo-footer {
            width:50px;
            margin-bottom: 20px;
        }
    }

    ul.teo-footer-links {
        list-style-type: none;
        padding-left: 0;
        margin-left: 0;

        li.teo-footer-link-title {
            font-family: $font-family-bold;
            font-weight: bold;
            font-size:15px;
        }

        li {
            margin-top: .35em;
            a {
                font-size:14px;
                text-decoration: none;
                color:var(--teo_MasterGray);

                &:hover {
                    text-decoration: underline;
                }
            }
        }
    }

    .footer-bottom {
        width: 100%;
        background: var(--teo_MasterGray);
        color: var(--blanco);
        padding: 24px 0;

        .social-media-footer {
            display:flex;

            i {
                font-size: 14px;
                color: white;
                margin-right: 2em;
                border: solid 1px white;
                width: 24px;
                height: 24px;
                display: flex;
                align-items: center;
                justify-content: center;
                border-radius: 50%;
            }
        }
    }
}