// section.teo-fakewrapper {
//     padding:0;
// }

//contenedor falso que tiene un padding para simular que es un container fijo 😉
// ladys and gentlemans, introducing the fakewrapper...
//se usa dentro de un container-fluid pero actua como si fuese container fijo
//el lado contrario es el que sale gua'u de la caja...


// 👉
.fakewrapper-left {
  padding-left: calc((100vw - 1132px) / 2);
  padding-right: 16em;
}

@media (min-width:300px) {
  .fakewrapper-left {
    padding-left: 9%; 
    padding-right: 2em;
    padding-top: 2em;
    padding-bottom: 4em;
  }

  .fakewrapper-outsidebox {
    img {
      height: 100%;
      object-fit: cover;
      border-radius: 0;
    }
  }
}

@media (min-width:768px) {
  .fakewrapper-left {
    padding-left: 9%; 
    padding-right: 4em;
    padding-top: 2em;
    padding-bottom: 4em;
  }

  .fakewrapper-outsidebox {
    img {
      height: 100%;
      object-fit: cover;
    }
  }
}


@media (min-width:992px) {
  .fakewrapper-left {
    padding-left: calc((100vw - 950px) / 2 ); 
    padding-right: 8em;
    padding-top: 3em;
  }
}

@media (min-width:1200px) {
  .fakewrapper-left {
    padding-left: calc((100vw - 1130px) / 2 );
  }
}



// 👈
.fakewrapper-right {
  padding-right: calc((100vw - 1132px) / 2);
  padding-left: 16em;
}

@media (min-width:300px) {
  .fakewrapper-right {
    padding-right: 9%; 
    padding-left: 2em;
    padding-top: 2em;
    padding-bottom: 4em;
  }

  .fakewrapper-outsidebox {
      img {
        height: 100%;
        object-fit: cover;
        border-radius: 0;
      }
  }
}

@media (min-width:768px) {
  .fakewrapper-right {
    padding-right: 9%; 
    padding-left: 4em;
    padding-top: 2em;
    padding-bottom: 4em;
  }

  .fakewrapper-outsidebox {
    img {
      height: 100%;
      object-fit: cover;
    }
  }
}


@media (min-width:992px) {
  .fakewrapper-right {
    padding-right: calc((100vw - 950px) / 2 ); 
    padding-left: 8em;
    padding-top: 3em;
  }
}

@media (min-width:1200px) {
  .fakewrapper-right {
    padding-right: calc((100vw - 1130px) / 2 );
  }
}