.teo-checklistado {
    padding-right: 8em;

    .teo-check-item {
        border-bottom: solid 1px var(--teo_MasterGray);
        margin-bottom: 1em;
        transition: 0.3s;

        i {
            padding-right: .5em;
            font-size: 16px;
        }

        p {
            padding-left: 1.6em;
            padding-bottom: 0;
            padding-right: 2em;
        }

        &:hover {
            transform: translateX( 5%);
        }
    }
}

.sin-borde {
    .teo-check-item {
        border-bottom:0;
    }
}

//colores en hover

.teo-checklistado-tiendas {
    
    .teo-check-item:hover {
        border-bottom: solid 2px var(--teo_MasterRed);

        i {
            color:var(--teo_MasterRed);
        }
    }
}

.teo-checklistado-shoppi {
    
    .teo-check-item:hover {
        border-bottom: solid 2px var(--teo_Shoppi);

        i {
            color:var(--teo_Shoppi);
        }
    }
}



.teo-checklistado-cargo-full {
    
    .teo-check-item:hover {
        border-bottom: solid 2px var(--teo_Cargo);

        i {
            color:var(--teo_Cargo);
        }
    }
}

.teo-checklistado-tracks-full {
    
    .teo-check-item:hover {
        border-bottom: solid 2px var(--teo_Tracks);

        i {
            color:var(--teo_Tracks);
        }
    }
}

.teo-checklistado-pagos-full {
    
    .teo-check-item:hover {
        border-bottom: solid 2px var(--teo_Pagos);

        i {
            color:var(--teo_Pagos);
        }
    }
}

.teo-checklistado-software {
    padding-right: 0;
    .teo-check-item {

        i {
            color:var(--teo_Software);
        }
    }
}


.teo-checklistado-growth {
    padding-right: 0;
    .teo-check-item {

        i {
            color:var(--teo_Growth);
        }
    }
}

.teo-checklistado-cargo {
    padding-right: 0;
    .teo-check-item {

        i {
            color:var(--teo_Cargo);
        }
    }
}

.teo-software-negocios, .teo-cargo-dirigido, .teo-tracks-negocios, .teo-casos-block {
    img {
        border-radius: 1em;
    }
}

@media(max-width:992px) {
    .teo-checklistado {
        padding-right: 4em;
    }
}