.directores-container {
    .director-item {
        border-radius: 1em;
        color: var(--blanco);
        background: var(--teo_MasterGray);
        padding:2em;
        height:90%;

        img {
            width: 64px;
            height: 64px;
            border-radius: 50%;
            object-fit: cover;
            
            margin-top: calc(-32px + -3em);
        }

        p {
            margin-bottom:0;
        }

        .director-cargo {
            border-bottom: solid 1px white;
        }
    }
}

.el-equipo {
    .equipo-item {
        margin-bottom:1em;

        img {
            width:64px;
            height: 64px;
            border-radius: 50%;
            object-fit: cover;
        }

        p {
            margin-bottom: 0;
            padding-bottom: 0;
        }

        h6 {
            font-family: $font-family-semibold;
            font-weight: 600;
        }
    }
}

.blog-item {
    border-radius: 1em;
    min-height: 214px;
    background-size: cover;
    background-position: center center;
    //display: flex;
    //flex-direction: column;

    .blacklayer_ {
        background: rgba(0,0,0,0.3);
        border-radius: 1em;
        padding:2em 1.2em;
        display: flex;
        flex-direction: column;
        align-items: self-start;
        justify-content: center;
        height: 100%;
        min-height: 214px;
    }

    .cliente-desc {
        z-index: 1;
        position: relative;
        padding: 1em 2em;
        display: flex;
        justify-content: left;
        align-items: center;

        img {
            width: 24px;
            height: 24px;
            border-radius: 50%;
        }

        p {
            margin: 0;
            padding: 0;
            margin-left: .5em;
        }
    }

    h6 {
        z-index: 2;
        position: relative;
        display: block;
        padding: 0 1.3em;
    }

    a {
        margin: 0 1.3em;
        display: block;
        width: fit-content;
    }
 
}

@media (max-width:992px) {

    .blog-item {
        width:100%;
        min-width: unset;
        margin-top:2em;
    }

   
}

@media(max-width:765px) {
    .desktopspacer {
        display: none;
    }

    section.sumarte img {
        margin-top:2em;
    }

    
}

.timeline {
    position:relative;

    .circulo {
        position: absolute;
        top: -12px;
        left: 0;
        z-index: 3;
        transition: 0.3s;
    }

    .linea {
        width: 100%;
        height: 2px;
        background: red;
        position: absolute;
        transition: 0.3s;
    }

    .lineared {
        background: var(--teo_MasterRed);
        z-index: 2;
        width:0;
        
    }
    .lineagray {
        background: var(--teo_LightGraySuave);
        z-index: 1;
       
    }
}

@media(max-width:768px) {
    .timeline {
        display:none;
    }

    section.teo-timeline p {
        font-size:12px;
    }
}