section.teo-acordeon {
    //hacer visible los tabs
    .collapse:not(.show) {
        display:inherit;
    }
    .card {
        flex-direction: row;
        border:none;
        margin-bottom: 2em;
        background-color: transparent;

        .card-header {
            min-width: 350px;
            display: flex;
            align-items: center;
            justify-content: left;
            padding: 0;            
            max-height: 160px;   
            border-bottom:0;
            min-height: 160px;  
            border-radius: 1em;

           
            
            button {
                width: 100%;
                height: 100%;
                padding: 0;
                display: flex;
                align-items: center;
                background:var(--teo_Cargo);
                color:white;
                font-weight: bold;
                border-radius: 1em;

                img {
                    padding-left: 64px;
                    padding-right: 20px;
                }

                img.icon-off {
                    display:none;
                }

                img.icon-on {
                    display: block;
                }

                &:focus {
                    box-shadow:none;
                    text-decoration: none;
                }

                &:hover {
                    text-decoration: none;
                }
            }

            button.collapsed {
                background:white;
                color:var(--teo_LightGray);
                text-decoration: none;
                box-shadow: 0px 3px 6px rgba(0,0,0,0.1);

                img.icon-on {
                    display:none;
                }

                img.icon-off {
                    display: block;
                }

                &:hover {
                    background: var(--teo_Cargo);
                    color: var(--blanco);
                }
            }

            button.acordeon-growth.collapsed {
                background:white;
                color:var(--teo_LightGray);
                text-decoration: none;
                box-shadow: 0px 3px 6px rgba(0,0,0,0.1);

                img.icon-on {
                    display:none;
                }

                img.icon-off {
                    display: block;
                }

                &:hover {
                    background: var(--teo_Growth);
                    color: var(--blanco);

                    img.icon-on {
                        display:block;
                    }
    
                    img.icon-off {
                        display: none;
                    }
                }
            }

            button.acordeon-growth {
                background:var(--teo_Growth);
            }
        }

        .collapsing {
            width: 100%;
            display: flex;
            align-items: center;
        }

        .collapse.show {
            // max-height: 160px;
            // overflow: hidden;
            width: 100%;
            display: flex;
            align-items: center;
        }

        .card-body {
            padding-left: 2em;
            padding-top: 1em;

            .teo-check-item {

                &:hover {
                    transform: translateX( 1%);
                }
            }
        }
    }
}


@media (max-width:992px) {
    section.teo-acordeon {
        .card {
            flex-direction: column;

            .card-header {
                button {
                    height: 160px;
                }
            }

            .card-body {
                padding-left: 1em;
            }
        }

        .collapse.show {
            margin-top: 2em;
        }

        .teo-checklistado {
            padding-right: 0 !important;
        }
    }
}

@media (max-width:420px) {
    section.teo-acordeon {
        .card {
            .card-header {
                min-width: 100%;

                button {
                    img {
                        padding-left: 24px;
                    }
                }
            }
        }
    }
}